<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="assignBdoCardForm"
        ref="assignBdoCardForm"
        class="custom-form"
        @submit.prevent="assign"
      >
        <v-col
          cols="12"
          class="mb-4"
        >
          <span class="input-label mr-1">Klient przypisany do karty: </span>
          <span class="">{{ bdoCard.client && bdoCard.client.name || '-' }}</span>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="client"
            :items="clients"
            item-value="id"
            item-text="name"
            label="Klient"
            placeholder="Wybierz klienta"
            outlined
            dense
            clearable
            @input="fetchOrders"
          />
        </v-col>

        <v-col cols="12">
          <v-menu
            v-model="showDatePicker"
            v-bind="attributes.menu"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="formattedValue"
                label="Data"
                placeholder="Wybierz datę"
                v-bind="{...attrs, ...attributes.textField}"
                v-on="on"
              >
                <template #prepend-inner>
                  <Icon
                    name="date"
                    size="small"
                    class="mr-2"
                  />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              v-bind="attributes.datePicker"
              @input="showDatePicker = false"
              @change="fetchOrders"
            />
          </v-menu>
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="orderId"
            :items="orders"
            item-value="id"
            item-text="parsedData"
            :rules="[rules.required]"
            label="Zlecenie"
            placeholder="Wybierz zlecenie"
            outlined
            dense
          />
        </v-col>
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :loading="isProcessing"
        type="submit"
        form="assignBdoCardForm"
      >
        Przypisz
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import { mapState, mapActions } from 'vuex'
import { dateStringFormat } from '../../utils'
import rules from '../../utils/validators'
import attributes from '../../const/datePickerAttrributes'
import afterFormSubmitted from '../../mixins/afterFormSubmitted'

export default {
  components: {
    DialogFormWrapper
  },
  mixins: [afterFormSubmitted],
  data () {
    return {
      rules,
      attributes,
      showDatePicker: false,
      orderId: null,
      client: null,
      date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders.simplifiedList,
      clients: state => state.clients.simplifiedList,
      department: state => state.core.department,
      bdoCard: state => state.bdoCard.entity,
      isProcessing: state => state.bdoCard.isProcessing,
    }),
    formattedValue: {
      get () {
        return this.date ? dateStringFormat(this.date) : ''
      },
      set (value) {
        this.date = value
      }
    }
  },
  created () {
    this.client = this.bdoCard?.client
    this.fetchOrders()
    const params = {
      departmentId: this.department.id,
      filters: {
        withoutPatron: true
      }
    }
    this.getSimplifiedClients({ params })
  },
  methods: {
    ...mapActions({
      getSimplifiedOrders: 'orders/getSimplifiedItems',
      assignOrderToBdoCard: 'bdoCard/assignOrderToBdoCard',
      getSimplifiedClients: 'clients/getSimplifiedItems',
    }),
    fetchOrders () {
      const params = {
        departmentId: this.department.id,
        forBdoCard: true,
        filters: {
          done: false,
          clientIds: this.client ? [this.client.id] : undefined,
          daterange: this.date ? [this.date] : undefined
        }
      }
      this.getSimplifiedOrders({ params })
        .then(() => {
          this.orderId = null
        })
    },
    assign () {
      if (this.$refs.assignBdoCardForm.validate()) {
        this.assignOrderToBdoCard({ orderId: this.orderId })
          .then(() => { this.afterFormSubmitted('Przypisano kartę') })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-list-item__content > .v-list-item__title {
  white-space: wrap !important;
  text-overflow: clip !important;
  white-space: break-spaces !important;
}
</style>
